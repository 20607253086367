export const environment = {
    local       : false,
    production  : false,
    hmr         : false,

    appName: `ORCA [[ STAGING ]]`,

    wsPort:     80,
    wsHost:     'https://apistaging.orcaapp.com.br',
    wsAppId:     "7F79E681D29F73C8",
    apiHost:     'https://apistaging.orcaapp.com.br',
    baseUrl:     'https://painelstaging.orcaapp.com.br',
    apiUrl:     'https://apistaging.orcaapp.com.br/api',
    storageUrl:  'https://apistaging.orcaapp.com.br/storage',
    
    pusherKey:   'e3a3289a044ff1ffaa31',
    pusherCluster: 'mt1',
    broadcaster: 'pusher',
};